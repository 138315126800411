/* Has to use exactly the same syntax declaration as bootstrap/dist/css/bootstrap.css to override them */

body {
  /* color: #222; */
  background: #fff;
  /* font: 400 14px CoreSans, Arial, sans-serif; */
}

a.navbarLink {
  color: #0056b3;
}
a.navbarLink:hover {
  text-decoration: none;
  color: #007bff;
}

.h1, h1 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.h2, h2 {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.h3, h3 {
  font-size: 1.1rem;
}

.navbar-nav {
  flex-direction: row;
}

.textBlue {
  color: #1e67b6;
}

.textRed {
  color: rgb(179, 29, 29);
}

input {
  padding: 6px 12px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(206, 212, 218);
  color: rgb(73, 80, 87);
}

.customToastStyle {
  background: #000000;
}


/*
  ==============================
  react-tag-autocomplete (start)
  ==============================
*/

.react-tags {
  position: relative;
  padding: 6px 12px 0 12px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(206, 212, 218);
  color: rgb(73, 80, 87);

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2em;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: rgb(231, 251, 255);

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

/*
  ============================
  react-tag-autocomplete (end)
  ============================
*/
